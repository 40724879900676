import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import { encryptData } from '../../modules/login/encryption';
import { Storage } from 'react-jhipster';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<any> {}

export const Login = (props: ILoginProps) => {
  const [showModal, setShowModal] = useState(props.showModal);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleLogin = (username, password, rememberMe) => {
    const encrypted = encryptData(password);
    props.login(username, encrypted, rememberMe);
  };

  const handleClose = () => {
    setShowModal(false);
    // props.history.push('/');
  };

  const { location, isAuthenticated, loading, is_vetted, process_vetting } = props;
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };
  if (!is_vetted && process_vetting) {
    return <Redirect to={'employeeForm'} />;
  }
  if (isAuthenticated && !loading && Storage.session.get('jhi-authenticationToken')) {
    if (!Storage.local.get('organizationSelected')) {
      return <Redirect
      to={{
        pathname: '/organizations',
        state: { from: props.location },
      }}
    />
    }
    return <Redirect to={from} />;
  }
  return <LoginModal showModal={showModal} handleLogin={handleLogin} handleClose={handleClose} loginError={props.loginError} showSpinner = {props.loading}/>;
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  loading: authentication.loading,
  is_vetted: authentication.is_vetted,
  process_vetting: authentication.process_vetting,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
