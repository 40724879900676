/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Box,
  Typography,
  List,
  styled,
  TableRow,
  Table,
  TableBody,
  Select,
} from '@material-ui/core';
import { connect } from 'react-redux';
import './applicants.scss';
import { Drawer, IconButton, Divider } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import { width } from '@fortawesome/free-solid-svg-icons/faCogs';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import moment from 'moment-timezone';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import FileViewer from 'react-file-viewer';
import Modal from '@material-ui/core/Modal';
import { exportPDF } from 'app/modules/vetting/applicants/exportPDF';
import { useHistory } from 'react-router-dom';
import { render } from '@testing-library/react';
import tableContent, { SummaryPdfTable } from 'app/modules/vetting/applicants/SummaryPdfTable';
import Link from '@material-ui/core/Link';
import ReactDOM from 'react-dom';

export const ApplicantSummary = (props: any) => {
  const vettingApiUrl = 'api/vetting';
  const history = useHistory();

  const useStyles = makeStyles(theme => ({
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      ...theme.mixins.toolbar,
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    avatar: {
      width: theme.spacing(18),
      height: theme.spacing(18),
      margin: 'auto',
      float: 'right',
      border: '2px solid grey',
    },
  }));

  const initialvalues = {
    requestUser: '',
    summaryScreener: '',
    summaryPerson: '',
    summaryDocument: [],
    summarySupportingDocument: [],
    summaryPersonalInformation: '',
    summaryOrganization: '',
    summaryAboutPosition: '',
    summaryEducation: [],
    summaryEmployment: [],
    summaryEmploymentHistoryFiles: [],
    summaryOthers: [],
    summaryEmploymentReferences: [],
    summaryEducationReferences: '',
    summaryOtherReferences: '',
    summaryVettingActivity: [],
    electoral_roll_entry: '',
    connect_api_information: '',
    ref_tracking: '',
    employment_ref_response: '',
  };
  const initialPdfValues = {
    requestUser: '',
    summaryScreener: '',
    summaryPerson: '',
    personalInformation: '',
    summaryOrganization: '',
    summaryEmployment: [],
    summaryEmploymentHistoryFiles: [],
    summaryEmploymentReferences: [],
    summaryOthers: [],
    summaryOtherReferences: '',
    summarySupportingDocument: [],
    electoral_roll_entry: '',
    connect_api_information: '',
    documents: [],
    ref_tracking: '',
    employment_ref_response: '',
  };
  const [summaryValues, setSummaryValues] = useState(initialvalues);
  const [pdfData, setPdfData] = useState(initialPdfValues);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [summaryStatus, setSummaryStatus] = useState('NewApplicant');
  const [docData, setDocData] = useState({ ext: '', file: '' });
  const [personId, setPersonId] = useState(null);

  useEffect(() => {
    setPersonId(props.applicantData.personId);
    setSummaryValues({
      requestUser: props.applicantData.requestUser,
      summaryScreener: props.applicantData.summaryScreener,
      summaryPerson: props.applicantData.summaryPerson,
      summaryDocument: props.applicantData['summaryDocument'],
      summarySupportingDocument: props.applicantData['summarySupportingDocument'],
      summaryAboutPosition: props.applicantData.summaryAboutPosition,
      summaryPersonalInformation: props.applicantData.summaryPersonalInformation,
      summaryEmploymentHistoryFiles: props.applicantData['summaryEmploymentHistoryFiles'],
      summaryOrganization: props.applicantData.summaryOrganization,
      summaryEducation: props.applicantData['summaryEducation'],
      summaryEmployment: props.applicantData['summaryEmployment'],
      summaryOthers: props.applicantData['summaryOthers'],
      summaryEmploymentReferences: props.applicantData['summaryEmploymentReferences'],
      summaryEducationReferences: props.applicantData['summaryEducationReferences'],
      summaryOtherReferences: props.applicantData['summaryOtherReferences'],
      summaryVettingActivity: props.applicantData['summaryVettingActivity'],
      electoral_roll_entry: props.applicantData['electoral_roll_entry'],
      connect_api_information: props.applicantData['connect_api_information'],
      ref_tracking: props.applicantData['ref_tracking'],
      employment_ref_response: props.applicantData['employment_ref_response'],
    });
    setPdfData({
      requestUser: props.applicantData.requestUser,
      summaryScreener: props.applicantData.summaryScreener,
      summaryPerson: props.applicantData.summaryPerson,
      personalInformation: props.applicantData.summaryPersonalInformation,
      summaryOrganization: props.applicantData.summaryOrganization,
      summaryEmployment: props.applicantData['summaryEmployment'],
      summaryEmploymentHistoryFiles: props.applicantData['summaryEmploymentHistoryFiles'],
      summaryEmploymentReferences: props.applicantData['summaryEmploymentReferences'],
      summaryOthers: props.applicantData['summaryOthers'],
      summaryOtherReferences: props.applicantData['summaryOtherReferences'],
      summarySupportingDocument: props.applicantData['summarySupportingDocument'],
      electoral_roll_entry: props.applicantData['electoral_roll_entry'],
      connect_api_information: props.applicantData['connect_api_information'],
      documents: props.applicantData['summaryDocument'],
      ref_tracking: props.applicantData['ref_tracking'],
      employment_ref_response: props.applicantData['employment_ref_response'],
    });
  }, [props.applicantData]);

  const pdf = () => {
    setPdfData({
      requestUser: props.applicantData.requestUser,
      summaryScreener: props.applicantData.summaryScreener,
      summaryPerson: props.applicantData.summaryPerson,
      personalInformation: props.applicantData.summaryPersonalInformation,
      summaryOrganization: props.applicantData.summaryOrganization,
      summaryEmployment: props.applicantData['summaryEmployment'],
      summaryEmploymentHistoryFiles: props.applicantData['summaryEmploymentHistoryFiles'],
      summaryEmploymentReferences: props.applicantData['summaryEmploymentReferences'],
      summaryOthers: props.applicantData['summaryOthers'],
      summaryOtherReferences: props.applicantData['summaryOtherReferences'],
      summarySupportingDocument: props.applicantData['summarySupportingDocument'],
      electoral_roll_entry: props.applicantData['electoral_roll_entry'],
      connect_api_information: props.applicantData['connect_api_information'],
      documents: props.applicantData['summaryDocument'],
      ref_tracking: props.applicantData['ref_tracking'],
      employment_ref_response: props.applicantData['employment_ref_response'],
    });
    exportPDF(pdfData, 'SWF BS7858 vetting report.pdf');
  };

  const handleDocView = (ext, file) => {
    setModalOpen(true);
    setDocData({ ext, file });
    return 0;
  };

  const handleCloseViewDocModal = () => {
    setModalOpen(false);
    setDocData({ ext: '', file: '' });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const formatDate = date => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };
  const formatTime = time => {
    return moment(time).format('h:mm a');
  };
  const calculateDifference = (startDate: string, endDate: string) => {
    let difference = 0;
    let diff = 0;

    if (startDate && endDate) {
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);

      const Difference_In_Time = date2.getTime() - date1.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      difference = difference + Difference_In_Days;
    }

    if (difference > 365) {
      diff = Math.floor(difference / 365);
    } else {
      diff = 0;
    }
    return `${diff} years`;
  };

  const handleDrawerClose = () => {
    props.handleClose();
    setSummaryValues(initialvalues);
    setPdfData(initialPdfValues);
  };

  const newApplicant = [
    { value: 'NewApplicant', label: 'New Applicant' },
    { value: 'Submitted', label: 'Submitted' },
    { value: 'InvalidApplication', label: 'Invalid Application' },
  ];
  const Submitted = [
    { value: 'Submitted', label: 'Submitted' },
    { value: 'FirstReview', label: 'Pending Review' },
    { value: 'InvalidApplication', label: 'Invalid Application' },
    { value: 'Failed', label: 'Failed' },
  ];
  const FirstReview = [
    { value: 'FirstReview', label: 'Pending Review' },
    { value: 'InProgress', label: 'In Progress' },
    { value: 'InvalidApplication', label: 'Invalid Application' },
    { value: 'Failed', label: 'Failed' },
  ];
  const InProgress = [
    { value: 'InProgress', label: 'In Progress' },
    { value: 'PreVetted', label: 'Pre Vetted' },
    { value: 'InvalidApplication', label: 'Invalid Application' },
    { value: 'Failed', label: 'Failed' },
  ];
  const PreVetted = [
    { value: 'PreVetted', label: 'Pre Vetted' },
    { value: 'InvalidApplication', label: 'Invalid Application' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Failed', label: 'Failed' },
  ];
  const InvalidApplication = [
    { value: 'InvalidApplication', label: 'Invalid Application' },
    { value: 'InProgress', label: 'In Progress' },
    { value: 'PreVetted', label: 'Pre Vetted' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Failed', label: 'Failed' },
  ];
  const Completed = [{ value: 'Completed', label: 'Completed' }];
  const Failed = [{ value: 'Failed', label: 'Failed' }];
  const None = [{ value: 'Select', label: 'Select' }];

  const handleChangeDropdown = (event: any) => {
    const id = summaryValues.summaryPerson && summaryValues.summaryPerson['id'];
    const oldStatus = summaryValues.summaryPerson && summaryValues.summaryPerson['vetting_status'].toString();
    const newStatus = event.target.value.toString();
    const data = {
      person_id: id,
      oldState: oldStatus,
      newState: newStatus,
    };
    props.summaryReportUpdateEntity(data);
    props.handleClose();
    setSummaryStatus(event.target.value);
  };

  useEffect(() => {
    setSummaryStatus(summaryValues.summaryPerson && summaryValues.summaryPerson['vetting_status']);
  });

  const employmentDetailsList = () => {
    if (summaryValues.summaryEmployment && summaryValues.summaryEmployment.length > 0) {
      return summaryValues.summaryEmployment.map((employment, index) => {
        return (
          <Box key={index}>
            <Box sx={{ flexGrow: 1 }} className={'sectionPadding'}>
              <Box component={'div'} className={'row justify-content-start align-items-center'}>
                <Box component={'div'} className={'col-md-4'}>
                  <Typography>
                    <b>Reference Name :</b>{' '}
                    {employment.contact_name}
                  </Typography>
                </Box>
                <Box component={'div'} className={'col-md-6'}>
                  <Typography>
                    <b>Reference Contact :</b>{' '}
                    {employment.contact_email}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={'sectionPadding'}>
              <TableContainer component={Paper}>
                <Table className={'table-bordered summaryTable'}>
                  <TableHead className={'trackSectionTableHead summaryTableHead'}>
                    <TableRow className={'trackSectionTableRow'}>
                      <TableCell align={'center'}>Employer</TableCell>
                      <TableCell align="center">Job Title</TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Post Code</TableCell>
                      <TableCell align="center">From Till</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={'addressTableBody summaryTableBody'}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {employment.company_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {employment.job_title}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {employment.company_location}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {employment.post_code}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate(employment.emp_start_date)}-{formatDate(employment.emp_end_date)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {calculateDifference(employment.emp_start_date, employment.emp_end_date)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box className={'sectionPadding'}>
              <Typography>
                <b>Reasons for Leaving :</b>
              </Typography>
              <Typography>{employment.leaving_reason}</Typography>
            </Box>

            <Box className={'sectionPadding'}>
              <Divider />
            </Box>
          </Box>
        );
      });
    } else {
      return (
        <Box>
          <Box sx={{ flexGrow: 1 }} className={'sectionPadding'}>
            <Box component={'div'} className={'row justify-content-start align-items-center'}>
              <Box component={'div'} className={'col-md-4'}>
                <Typography>
                  <b>Reference Name:</b>---
                </Typography>
              </Box>
              <Box component={'div'} className={'col-md-4'}>
                <Typography>
                  <b>Reference Contact:</b>---
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={'sectionPadding'}>
            <TableContainer component={Paper}>
              <Table className={'table-bordered summaryTable'}>
                <TableHead className={'trackSectionTableHead summaryTableHead'}>
                  <TableRow className={'trackSectionTableRow'}>
                    <TableCell>Employer</TableCell>
                    <TableCell align="center">Job Title</TableCell>
                    <TableCell align="center">Location</TableCell>
                    <TableCell align="center">Post Code</TableCell>
                    <TableCell align="center">From Till</TableCell>
                    <TableCell align="center">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={'addressTableBody summaryTableBody'}>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={'sectionPadding'}>
            <Typography>
              <b>Reasons for Leaving:</b>
            </Typography>
            <Typography></Typography>
          </Box>
        </Box>
      );
    }
  };
  const educationDetailsList = () => {
    if (summaryValues.summaryEducation && summaryValues.summaryEducation.length > 0) {
      return (
        <TableContainer className={'sectionPadding'} component={Paper}>
          <Table className={'table-bordered summaryTable'}>
            <TableHead className={'trackSectionTableHead summaryTableHead'}>
              <TableRow className={'trackSectionTableRow'}>
                <TableCell align={'center'}>Education Title</TableCell>
                <TableCell align="center">Institute Name</TableCell>
                <TableCell align="center">Location</TableCell>
                <TableCell align="center">Post Code</TableCell>
                <TableCell align="center">From Till</TableCell>
                <TableCell align="center">Attachment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={'addressTableBody summaryTableBody'}>
              {summaryValues.summaryEducation.map((education, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {education.degree_title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {education.institute_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {education.institute_location}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {education.post_code}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {formatDate(education.start_date)}-{formatDate(education.end_date)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {education.proof ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleDocView(education.proof['ext'], education.proof['file']);
                          }}
                          className={'attachmentIconExist'}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faEye} className={'attachmentIcon'} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <TableContainer className={'sectionPadding'} component={Paper}>
          <Table className={'table-bordered summaryTable'}>
            <TableHead className={'trackSectionTableHead summaryTableHead'}>
              <TableRow className={'trackSectionTableRow'}>
                <TableCell align={'center'}>Education Title</TableCell>
                <TableCell align="center">Institute Name</TableCell>
                <TableCell align="center">Location</TableCell>
                <TableCell align="center">Post Code</TableCell>
                <TableCell align="center">From Till</TableCell>
                <TableCell align="center">Attachment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={'addressTableBody summaryTableBody'}>
              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };
  const otherDetailList = () => {
    if (summaryValues.summaryOthers && summaryValues.summaryOthers.length > 0) {
      return summaryValues.summaryOthers.map((other, index) => {
        return (
          <Box key={index} className={'sectionPadding'}>
            <Box sx={{ flexGrow: 1 }}>
              <Box component={'div'} className={'row justify-content-start align-items-center'}>
                <Box component={'div'} className={'col-md-4'}>
                  <Typography>
                    <b>Reference Name:</b>
                    {summaryValues.summaryOtherReferences && summaryValues.summaryOtherReferences['ref_name']}
                  </Typography>
                </Box>
                <Box component={'div'} className={'col-md-4'}>
                  <Typography>
                    <b>Location:</b>
                    {summaryValues.summaryOtherReferences && summaryValues.summaryOtherReferences['ref_address']}
                  </Typography>
                </Box>
                <Box component={'div'} className={'col-md-4'}>
                  <Typography>
                    <b>Reference Contact:</b>
                    {summaryValues.summaryOtherReferences && summaryValues.summaryOtherReferences['ref_email']}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={'sectionPadding'}>
              <TableContainer component={Paper}>
                <Table className={'table-bordered summaryTable'}>
                  <TableHead className={'trackSectionTableHead summaryTableHead'}>
                    <TableRow className={'trackSectionTableRow'}>
                      <TableCell align="center">Reason</TableCell>
                      <TableCell align="center">Duration</TableCell>
                      <TableCell align="center">Attachment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={'addressTableBody summaryTableBody'}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {other.reason}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate(other.start_date)}-{formatDate(other.end_date)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {other.proof ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleDocView(other.proof['ext'], other.proof['file']);
                            }}
                            className={'attachmentIconExist'}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faEye} className={'attachmentIcon'} />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box className={'sectionPadding'}>
              <Typography>
                <b>Extra Note:</b>
              </Typography>
              <Typography>{other.note}</Typography>
            </Box>
          </Box>
        );
      });
    } else {
      return (
        <Box className={'sectionPadding'}>
          <Box sx={{ flexGrow: 1 }}>
            <Box component={'div'} className={'row justify-content-start align-items-center'}>
              <Box component={'div'} className={'col-md-4'}>
                <Typography>
                  <b>Reference Name:</b>
                </Typography>
              </Box>
              <Box component={'div'} className={'col-md-4'}>
                <Typography>
                  <b>Location:</b>
                </Typography>
              </Box>
              <Box component={'div'} className={'col-md-4'}>
                <Typography>
                  <b>Reference Contact:</b>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={'sectionPadding'}>
            <TableContainer component={Paper}>
              <Table className={'table-bordered summaryTable'}>
                <TableHead className={'trackSectionTableHead summaryTableHead'}>
                  <TableRow className={'trackSectionTableRow'}>
                    <TableCell align="center">Reason</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Attachment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={'addressTableBody summaryTableBody'}>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={'sectionPadding'}>
            <Typography>
              <b>Extra Note:</b>
            </Typography>
            <Typography></Typography>
          </Box>
        </Box>
      );
    }
  };

  const employmentGapProof = () => {
    if (summaryValues.summaryEmploymentReferences && summaryValues.summaryEmploymentReferences.length > 0) {
      return summaryValues.summaryEmploymentReferences.map((entity, index) => {
        return (
          <Box key={index} className={'sectionPadding'}>
            <Box sx={{ flexGrow: 1 }}>
              <Box component={'div'} className={'row justify-content-start align-items-center'}>
                <Box component={'div'} className={'col-md-4'}>
                  <Typography>
                    <b>Reference Name:</b>
                    {entity && entity['ref_name']}
                  </Typography>
                </Box>

                <Box component={'div'} className={'col-md-6'}>
                  <Typography>
                    <b>Reference Contact:</b>
                    {entity && entity['ref_email']}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={'sectionPadding'}>
              <TableContainer component={Paper}>
                <Table className={'table-bordered summaryTable'}>
                  <TableHead className={'trackSectionTableHead summaryTableHead'}>
                    <TableRow className={'trackSectionTableRow'}>
                      <TableCell align="center">Reason</TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Attachment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={'addressTableBody summaryTableBody'}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {entity.gap_reason}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {entity && entity['ref_address']}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {entity.proof ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleDocView(entity.proof['ext'], entity.proof['file']);
                            }}
                            className={'attachmentIconExist'}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faEye} className={'attachmentIcon'} />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        );
      });
    }
  };

  const handleDownloadFile = id => {
    axios.get(`${vettingApiUrl}/download-file/${id}`).then(responseData => {
      fetch(responseData.data.file).then(response => {
        response.blob().then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
          const name = 'Sample.' + responseData.data.ext;
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = name;
          alink.click();
        });
      });
    });
  };

  function capitalizeFirstLetter(summaryValues) {
    return summaryValues.charAt(0).toUpperCase() + summaryValues.slice(1);
  }

  return (
    <>
      <Box className={'drawer-close-button-div'}>
        {tableContent(pdfData)}
        <IconButton className={'drawer-icon-button'} onClick={props.handleClose}>
          <CloseIcon className={'drawer-close-icon'} onClick={handleDrawerClose} />
        </IconButton>
      </Box>
      <Box flexDirection="row" style={{ background: 'rgb(243, 243, 243)', width: '100%' }}>
        <Box
          component={'div'}
          className={'row'}
          style={{
            marginRight: 'unset',
            marginLeft: 'unset',
          }}
        >
          <Box component={'div'} className={'col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 '}>
            <Box
              component={'div'}
              flexGrow={2}
              style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                width: 'auto',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box className={'summaryBoxPadding'} component={'div'}>
                <Box component={'div'} className={'row justify-content-center align-items-center'}>
                  <Box component={'div'} className={'col-md-6'}>
                    <Box>
                      <Typography className={'summarySectionUSerName'}>
                        {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['first_name']}{' '}
                        {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['middle_name']}{' '}
                        {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['last_name']} - Vetting
                        Application Form
                      </Typography>
                    </Box>
                  </Box>
                  <Box component={'div'} className={'col-md-6 textEnd'}>
                    {
                      summaryValues.summaryPerson && (
                        // summaryValues.summaryPerson['vetting_status'] === 'PreVetted' ||
                        // summaryValues.summaryPerson['vetting_status'] === 'Completed' || (
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={pdf}
                          style={{ cursor: 'pointer' }}
                          className={'summarySectionHeaderDownloadIcon mr-2'}
                        />
                      )
                      // )
                    }
                    <Button onClick={() => history.push(`/employeeForm/${personId}`)} className={'summarySectionEditButton'}>
                      <FontAwesomeIcon icon={faEdit} className={'summarySectionHeaderEditIcon'} /> Edit
                    </Button>

                    <FormControl className={'summarySectionInprogressButton'}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={summaryStatus}
                        className={'summarySectionInProgressOption'}
                        onChange={e => {
                          handleChangeDropdown(e);
                        }}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        {!summaryStatus &&
                          None.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              Select
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'NewApplicant' &&
                          newApplicant.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'Submitted' &&
                          Submitted.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'FirstReview' &&
                          FirstReview.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'InProgress' &&
                          InProgress.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'PreVetted' &&
                          PreVetted.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'InvalidApplication' &&
                          InvalidApplication.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'Completed' &&
                          Completed.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        {summaryStatus &&
                          summaryStatus === 'Failed' &&
                          Failed.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Divider />

              <Box className={'summaryBoxPadding'} component={'div'}>
                <Box className={'sectionPadding'}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <Box component={'div'}>
                        <Typography>
                          <b>Applied for :</b>{' '}
                          {summaryValues.summaryAboutPosition && summaryValues.summaryAboutPosition['applied_position']}
                        </Typography>
                      </Box>
                      <Box component={'div'}>
                        <Typography>
                          <b>Availability :</b>{' '}
                          {summaryValues.summaryAboutPosition && capitalizeFirstLetter(summaryValues.summaryAboutPosition['job_availability'])}
                        </Typography>
                      </Box>
                      <Box component={'div'}>
                        <Typography>
                          <b>Start date : </b>
                          {summaryValues.summaryAboutPosition && summaryValues.summaryAboutPosition['expected_date_start']
                            ? formatDate(summaryValues.summaryAboutPosition['expected_date_start'])
                            : '---'}
                        </Typography>
                      </Box>
                      <Box component={'div'} className={'summaryAboutInfo mt-4'}>
                        <Typography className={'summaryAboutInfoText'}>About You</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} className={'summaryReportImageSection'}>
                      <Avatar
                        src={summaryValues.summaryDocument && summaryValues.summaryDocument['upload_photo']}
                        className={classes.avatar}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ flexGrow: 1 }} className={'sectionPadding'}>
                  <Box component={'div'} className={'row justify-content-start'}>
                    <Box component={'div'} className={'col-md-4'}>
                      <Typography>
                        <b>First Name :</b>{' '}
                        {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['first_name']}{' '}
                        {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['middle_name']}
                      </Typography>
                    </Box>
                    <Box component={'div'} className={'col-md-4'}>
                      <Typography>
                        <b>Last Name :</b>{' '}
                        {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['last_name']}
                      </Typography>
                    </Box>
                    <Box component={'div'} className={'col-md-4'}>
                      <Typography>
                        <b>Address :</b>{' '}
                        {summaryValues.summaryPersonalInformation &&
                          summaryValues.summaryPersonalInformation['building_name'] &&
                          summaryValues.summaryPersonalInformation['building_name'] +
                            ' ' +
                            summaryValues.summaryPersonalInformation['building_no'] +
                            ' Street ' +
                            summaryValues.summaryPersonalInformation['street'] +
                            ', ' +
                            summaryValues.summaryPersonalInformation['city']}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className={'sectionPadding'}>
                  <TableContainer component={Paper}>
                    <Table className={'table-bordered summaryTable'}>
                      <TableHead className={'trackSectionTableHead summaryTableHead'}>
                        <TableRow className={'trackSectionTableRow'}>
                          <TableCell align="center">Date of Birth</TableCell>
                          <TableCell align="center">Post Code</TableCell>
                          <TableCell align="center">NI NO.</TableCell>
                          <TableCell align="center">Telephone</TableCell>
                          <TableCell align="center">Mobile No.</TableCell>
                          <TableCell align="center">Email</TableCell>
                          <TableCell align="center">Right to Work</TableCell>
                          <TableCell align="center">Job Hour</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={'addressTableBody summaryTableBody'}>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation &&
                              summaryValues.summaryPersonalInformation['date_of_birth'] &&
                              formatDate(summaryValues.summaryPersonalInformation['date_of_birth'])}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['post_code']}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['national_insurance_no']}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['telephone_no']
                              ? summaryValues.summaryPersonalInformation['telephone_no']
                              : null}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['mobile_no']}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['email_address']}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && capitalizeFirstLetter(summaryValues.summaryPersonalInformation['work_uk'])}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryAboutPosition &&
                            summaryValues.summaryAboutPosition['shift_time'] &&
                            summaryValues.summaryAboutPosition['shift_time']['Morning']
                              ? 'Morning'
                              : ''}{' '}
                            {summaryValues.summaryAboutPosition &&
                            summaryValues.summaryAboutPosition['shift_time'] &&
                            summaryValues.summaryAboutPosition['shift_time']['Day']
                              ? 'Day'
                              : ''}{' '}
                            {summaryValues.summaryAboutPosition &&
                            summaryValues.summaryAboutPosition['shift_time'] &&
                            summaryValues.summaryAboutPosition['shift_time']['Night']
                              ? 'Night'
                              : ''}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box className={'sectionPadding'}>
                  <Typography>
                    <b>Extra Note :</b>
                  </Typography>
                  <Typography></Typography>
                </Box>

                <Box className={'sectionPadding mt-4 mb-4'}>
                  <Box component={'div'}>
                    <Typography component={'span'} className={'convictionSection'}>
                      Any Conviction
                    </Typography>{' '}
                    <Typography component={'span'} className={'ml-3'}>
                      {summaryValues.summaryPersonalInformation ? capitalizeFirstLetter(summaryValues.summaryPersonalInformation['crime_conviction']) : '---'}
                    </Typography>
                  </Box>
                </Box>

                <Box className={'sectionPadding'}>
                  <Typography>
                    <b>Details :</b>
                  </Typography>
                  <Typography>
                    {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['crime_conviction_details']}
                  </Typography>
                </Box>

                <Box className={'sectionPadding'}>
                  <TableContainer component={Paper}>
                    <Table className={'table-bordered summaryTable'}>
                      <TableHead className={'trackSectionTableHead summaryTableHead'}>
                        <TableRow className={'trackSectionTableRow'}>
                          <TableCell align="center">Driving License</TableCell>
                          <TableCell align="center">SIA License</TableCell>
                          <TableCell align="center">License NO.</TableCell>
                          <TableCell align="center">Name of License</TableCell>
                          <TableCell align="center">Expiry</TableCell>
                          <TableCell align="center">Attachment</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={'addressTableBody summaryTableBody'}>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && capitalizeFirstLetter(summaryValues.summaryPersonalInformation['driving_license_uk_uae'])}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && capitalizeFirstLetter(summaryValues.summaryPersonalInformation['sia_license'])}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['sia_license_no']}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['license_name']}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {summaryValues.summaryPersonalInformation && summaryValues.summaryPersonalInformation['license_expiration']
                              ? formatDate(summaryValues.summaryPersonalInformation['license_expiration'])
                              : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Box className={'row justify-content-center align-items-center'}>
                              {summaryValues.summaryPersonalInformation && (
                                <Box className={'col-6 d-flex justify-content-center align-items-center'}>
                                  <Box component={'span'}>License Front</Box>
                                  {summaryValues.summaryPersonalInformation['sia_license_image_front'] ? (
                                    <FontAwesomeIcon
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        handleDocView(
                                          summaryValues.summaryPersonalInformation['sia_license_image_front']['ext'],
                                          summaryValues.summaryPersonalInformation['sia_license_image_front']['file']
                                        );
                                      }}
                                      icon={faEye}
                                      className={'attachmentIconExist'}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      icon={faEye}
                                      className={'attachmentIcon'}
                                    />
                                  )}
                                </Box>
                              )}
                              {summaryValues.summaryPersonalInformation && (
                                <Box className={'col-6 d-flex justify-content-center align-items-center'}>
                                  <Box component={'span'}>License Back</Box>
                                  {summaryValues.summaryPersonalInformation['sia_license_image_back'] ? (
                                    <FontAwesomeIcon
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        handleDocView(
                                          summaryValues.summaryPersonalInformation['sia_license_image_back']['ext'],
                                          summaryValues.summaryPersonalInformation['sia_license_image_back']['file']
                                        );
                                      }}
                                      icon={faEye}
                                      className={'attachmentIconExist'}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      icon={faEye}
                                      className={'attachmentIcon'}
                                    />
                                  )}
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box className={'sectionPadding'}>
                  <Grid item xs={4}>
                    <Box component={'div'} className={'summaryAboutInfo mt-4'}>
                      <Typography className={'summaryAboutInfoText'}>Professional History</Typography>
                    </Box>
                  </Grid>
                </Box>
                {summaryValues.summaryEmployment && summaryValues.summaryEmployment.length > 0 && (
                  <>
                    <Box className={'sectionPadding'}>
                      <Box component={'div'} className={'summaryReportEducationalDetail'}>
                        <Typography>Employment Details</Typography>
                      </Box>
                    </Box>

                    <Box>{employmentDetailsList}</Box>
                  </>
                )}

                {summaryValues.summaryEmploymentReferences && summaryValues.summaryEmploymentReferences.length > 0 && (
                  <>
                    <Box className={'sectionPadding'}>
                      <Box component={'div'} className={'summaryReportEducationalDetail'}>
                        <Typography>Employment Gap Reference Details</Typography>
                      </Box>
                    </Box>
                    <Box className={'sectionPadding'}>{employmentGapProof}</Box>
                  </>
                )}

                {summaryValues.summaryEducation && summaryValues.summaryEducation.length > 0 && (
                  <>
                    <Box className={'sectionPadding'}>
                      <Box component={'div'} className={'summaryReportEducationalDetail'}>
                        <Typography>Educational Details</Typography>
                      </Box>
                    </Box>

                    <Box className={'sectionPadding'}>{educationDetailsList}</Box>
                  </>
                )}

                {summaryValues.summaryOthers && summaryValues.summaryOthers.length > 0 && (
                  <>
                    <Box className={'sectionPadding'}>
                      <Box component={'div'} className={'summaryReportEducationalDetail'}>
                        <Typography>Others</Typography>
                      </Box>
                    </Box>

                    <Box className={'sectionPadding'}>{otherDetailList}</Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box component={'div'} className={'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 p-0'}>
            <Box component={'div'} className={'text-center trackDocumentSection'} flexGrow={1}>
              <Box component={'div'} className={'trackDocumentSectionOuter'}>
                <Box component={'div'} className={'trackDocumentSectionHeader'}>
                  <Typography className={'activityTrackArea'}>Activity Track & Verified Documents</Typography>
                  <Divider />
                </Box>
                {summaryValues.summaryVettingActivity &&
                  summaryValues.summaryVettingActivity.length > 0 &&
                  summaryValues.summaryVettingActivity.map((value, index) => (
                    <Box key={index}>
                      <Box component={'div'} className={'trackDateSection'}>
                        <Typography>{formatDate(value.create_date)}</Typography>
                      </Box>
                      <Box component={'div'} className={'trackSectionProgress'}>
                        <Typography>{value.description}</Typography>
                      </Box>
                    </Box>
                  ))}

                <Box component={'div'} className={'trackSectionTable'}>
                  <TableContainer component={Paper}>
                    <Table className={'table-bordered'}>
                      <TableHead className={'trackSectionTableHead'}>
                        <TableRow>
                          <TableCell align="center">File</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Original Seen</TableCell>
                          <TableCell align="center">Download</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={'trackSectionTableRowStyle'}>
                          <TableCell component="th" scope="row">
                            <Typography className={'text-capitalize'}>Proof of ID</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument && summaryValues.summaryDocument['proof_of_id']
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument &&
                                summaryValues.summaryDocument &&
                                summaryValues.summaryDocument['proof_of_id'] &&
                                summaryValues.summaryDocument['proof_of_id']['original_seen'] === true
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                              onClick={() => props.updateOriginalSeen(summaryValues.summaryDocument['proof_of_id'])}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleDownloadFile(summaryValues.summaryDocument['proof_of_id']['id'])}
                              className={`trackSectionDownloadIcon ${
                                summaryValues.summaryDocument && summaryValues.summaryDocument['proof_of_id'] ? '' : 'disabled'
                              }`}
                            />
                            {summaryValues?.summaryDocument && summaryValues.summaryDocument['proof_of_id'] ? (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                onClick={() => {
                                  handleDocView(
                                    summaryValues.summaryDocument['proof_of_id_data']['ext'],
                                    summaryValues.summaryDocument['proof_of_id_data']['file']
                                  );
                                }}
                                icon={faEye}
                                className={'attachmentIconExist'}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                icon={faEye}
                                className={'attachmentIcon'}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow className={'trackSectionTableRowStyle'}>
                          <TableCell component="th" scope="row">
                            <Typography className={'text-capitalize'}>Proof of address</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument && summaryValues.summaryDocument['proof_of_address']
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument &&
                                summaryValues.summaryDocument['proof_of_address'] &&
                                summaryValues.summaryDocument['proof_of_address']['original_seen'] === true
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleDownloadFile(summaryValues.summaryDocument['proof_of_address']['id'])}
                              className={`trackSectionDownloadIcon ${
                                summaryValues.summaryDocument && summaryValues.summaryDocument['proof_of_address'] ? '' : 'disabled'
                              }`}
                            />

                            {summaryValues?.summaryDocument && summaryValues.summaryDocument['proof_of_address'] ? (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                onClick={() => {
                                  handleDocView(
                                    summaryValues.summaryDocument['proof_of_address_data']['ext'],
                                    summaryValues.summaryDocument['proof_of_address_data']['file']
                                  );
                                }}
                                icon={faEye}
                                className={'attachmentIconExist'}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                icon={faEye}
                                className={'attachmentIcon'}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow className={'trackSectionTableRowStyle'}>
                          <TableCell component="th" scope="row">
                            <Typography className={'text-capitalize'}>Right to work Report (BRP)</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument && summaryValues.summaryDocument['right_to_work']
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument &&
                                summaryValues.summaryDocument['right_to_work'] &&
                                summaryValues.summaryDocument['right_to_work']['original_seen'] === true
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleDownloadFile(summaryValues.summaryDocument['right_to_work']['id'])}
                              className={`trackSectionDownloadIcon ${
                                summaryValues.summaryDocument && summaryValues.summaryDocument['right_to_work'] ? '' : 'disabled'
                              }`}
                            />

                            {summaryValues && summaryValues.summaryDocument && summaryValues.summaryDocument['right_to_work'] ? (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                onClick={() => {
                                  handleDocView(
                                    summaryValues.summaryDocument['right_to_work_data']['ext'],
                                    summaryValues.summaryDocument['right_to_work_data']['file']
                                  );
                                }}
                                icon={faEye}
                                className={'attachmentIconExist'}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                icon={faEye}
                                className={'attachmentIcon'}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow className={'trackSectionTableRowStyle'}>
                          <TableCell component="th" scope="row">
                            <Typography className={'text-capitalize'}>P46 Form</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument && summaryValues.summaryDocument['p46_form']
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summaryDocument &&
                                summaryValues.summaryDocument['p46_form'] &&
                                summaryValues.summaryDocument['p46_form']['original_seen'] === true
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleDownloadFile(summaryValues.summaryDocument['p46_form']['id'])}
                              className={`trackSectionDownloadIcon ${
                                summaryValues.summaryDocument && summaryValues.summaryDocument['p46_form'] ? '' : 'disabled'
                              }`}
                            />

                            {summaryValues && summaryValues.summaryDocument && summaryValues.summaryDocument['p46_form'] ? (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                onClick={() => {
                                  handleDocView(
                                    summaryValues.summaryDocument['p46_form_data']['ext'],
                                    summaryValues.summaryDocument['p46_form_data']['file']
                                  );
                                }}
                                icon={faEye}
                                className={'attachmentIconExist'}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                icon={faEye}
                                className={'attachmentIcon'}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        {summaryValues.summarySupportingDocument && summaryValues.summarySupportingDocument.length > 0 ? (
                          summaryValues.summarySupportingDocument.map((file, index) => (
                            <TableRow key={index} className={'trackSectionTableRowStyle'}>
                              <TableCell component="th" scope="row">
                                <Typography className={'text-capitalize'}>Supporting Document</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <FontAwesomeIcon icon={faCheckCircle} className={'trackSectionStatusIconDownloaded'} />
                              </TableCell>
                              <TableCell align="center">
                              <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                summaryValues.summarySupportingDocument &&
                                summaryValues.summarySupportingDocument[index] &&
                                summaryValues.summarySupportingDocument[index]['original_seen'] === true
                                  ? 'trackSectionStatusIconDownloaded'
                                  : 'trackSectionStatusIcon'
                              }
                            />
                              </TableCell>
                              <TableCell align="center">
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleDownloadFile(file['id'])}
                                  className={'trackSectionDownloadIcon'}
                                />

                                <FontAwesomeIcon
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '15px',
                                  }}
                                  icon={faEye}
                                  className={'attachmentIcon'}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow className={'trackSectionTableRowStyle'}>
                            <TableCell component="th" scope="row">
                              Supporting Document
                            </TableCell>
                            <TableCell align="center">
                              <FontAwesomeIcon icon={faCheckCircle} className={'trackSectionStatusIcon'} />
                            </TableCell>
                            <TableCell align="center">
                              <FontAwesomeIcon icon={faCheckCircle} className={'trackSectionStatusIcon'} />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box className={'mt-3'}>
                  Complete BS7858 staff vetting process will take
                  <Typography className={'activityTrackTimeStyle'}>11 to 15 weeks.</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={modalOpen} className="file-view-modal-2">
        <Paper style={{ width: '100%', height: '100%' }}>
          <CloseIcon style={{ marginLeft: '0%', cursor: 'pointer' }} onClick={handleCloseViewDocModal}></CloseIcon>
          <div className="file-viewer-style">
            <FileViewer fileType={docData?.ext} filePath={docData?.file} />
          </div>
        </Paper>
      </Modal>
    </>
  );
};
