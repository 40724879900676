import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';

export const ImageDialogueComponent = props => {
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {}, [props.open]);

  return (
    <div>
      {props.open && (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
          <img className="image" src={`https://storage.googleapis.com/swfm-prod-images/${props.image}`} alt="no image" />
        </Dialog>
      )}
    </div>
  );
};
