import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import './deleted-shifts.scss';
import { getDeletedShifts, rescheduleShift, reset } from '../shift/shift.reducer';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import timePeriod from '../../shared/data/time_period.json';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';

import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Popper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../../entities/service-area/CustomToolbarComponent';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import {
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  getSelectedTimePeriodFilter,
} from '../../../utils/TimeFormatAndTimezone';
import { Autocomplete } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import SearchBar from 'material-ui-search-bar';
import { convertToPDF } from './converToPDF';
import { dateFTConverter, shiftListStartEndDate } from '../../../utils/TimeFormatAndTimezone';
import Spinner from 'app/commonUI/spinner/spinner';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '70vh',
  },
  clockoutButton: {
    textTransform: 'none',
    fontSize: 'x-small',
    borderRadius: '2px',
    color: '#fff',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#d9534f',
      boxShadow: '0 0 0 0.2rem rgba(0,0,0,0,11 )',
    },
  },
  clockinButton: {
    textTransform: 'none',
    fontSize: 'x-small',
    borderRadius: '0px',
    color: '#fff',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#d9534f',
      boxShadow: '0 0 0 0.2rem rgba(0,0,0,0,11 )',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  iconColor: {
    color: 'blue',
  },
  timeclockTimeBoxes: {
    borderRadius: 'unset',
  },
}));

export interface ITimeClock extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

function DeletedShifts(props) {
  const classes = useStyles();
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 1, text: 'Until now' });
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [values, setValues] = useState({
    people: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    current_day: '',
    pageNumber: '',
  });
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [locale, setLocale] = useState(props.setting.time_format);
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
  const [records, setRecords] = useState(props.deleted_shifts);
  const csvLinks = [''];
  const [selectedAction, setSelectedAction] = React.useState<any | null>(null);
  const [searched, setSearched] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    props.reset();
    props.resetPerson();
    props.getPersonForFilters();
  }, []);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
    setLocale(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
  }, [props.setting]);

  useEffect(() => {
    setRecords(props.deleted_shifts);
  }, [props.deleted_shifts]);

  useEffect(() => {
    if (pageNumber > 1) {
      props.getDeletedShifts(values, pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    props.getDeletedShifts(values, '');
  }, [values]);

  useEffect(() => {
    filterRows();
  }, [props.shifts]);

  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setSelectedPerson({ id: '', text: '' });
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };

  const setFilterValues = (name, value) => {
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleDateFrom = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
  };

  const handleDateTo = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSync = () => {
    props.reset();
    setPageNumber(1);
    setSearched('');
    setSelectedPerson(null);
    setValues({
      people: '',
      from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
      to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
      current_day: '',
      pageNumber: '',
    });
  };

  const handleRescheduleShift = id => {
    props.rescheduleShift(id, values);
  };

  const handleTimePeriodFilter = (_, e) => {
    if (e) {
      setTimePeriod({ id: e.id, text: e.text });

      switch (e.id) {
        case 1:
          setValues(oldValues => ({ ...oldValues, ['to_date']: moment().format('YYYY-MM-DD'), ['current_day']: '' }));
          setSelectedDateFrom(null);
          setSelectedDateTo(null);
          break;
        case 2:
          {
            const first = moment().format('YYYY-MM-DD');
            const last = moment().format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 3:
          {
            const first = handleFilterCurrentWeekStartDate();
            const last = handleFilterCurrentWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 4:
          {
            const first = handleFilterLastWeekStartDate();
            const last = handleFilterLastWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 5:
          {
            const first = moment().startOf('month').format('YYYY-MM-DD');
            const last = moment().endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 6:
          {
            const first = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            const last = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        default:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
    }
  };
  const setTimePeriodValues = (first, second) => {
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };

  const { shifts, match, loading } = props;

  const filterRows = () => {
    const filteredRows = props.shifts.filter(row => {
      return row.unixTime !== null && row.unixTime <= Date.now() / 1000;
    });
    setRecords(filteredRows);
  };

  const header = [
    { label: 'Shift Date/Time', key: 'start_time' },
    { label: 'Assignee Name', key: 'assignee' },
    { label: 'Status', key: 'status' },
    { label: 'Service Area', key: 'service_area' },
    { label: 'Reason', key: 'reason' },
    { label: 'Deleted By', key: 'deleted_by' },
  ];

  const jsonData = [];
  if (records.length > 0) {
    records.forEach(element => {
      const data = { ...element };
      data.start_time = dateFTConverter(data.start_time, dateFormat);
      (data.assignee = data.assignee ? data.assignee : null),
        (data.status = data.status ? data.status : null),
        (data.service_area = data.service_area ? data.service_area : null),
        (data.reason = data.reason ? data.reason : null),
        (data.deleted_by = data.deleted_by ? data.deleted_by : null),
        jsonData.push(data);
    });
  }

  const csvReport = {
    data: jsonData,
    headers: header,
    filename: 'Deleted Shifts.csv',
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.deleted_shifts.filter(row => {
      return (
        (row.start_time !== null && shiftListStartEndDate(row.start_time, dateFormat).includes(value)) ||
        (row.assignee && row.assignee.toLowerCase().includes(value)) ||
        (row.status && row.status.toLowerCase().includes(value)) ||
        (row.service_area && row.service_area.toLowerCase().includes(value)) ||
        (row.reason && row.reason.toLowerCase().includes(value)) ||
        (row.deleted_by && row.deleted_by.toString().includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  // const handleChange = event => {
  //   setSelectedAction(event.target.value);
  // };

  return (
    <div className="entities-reports-main-container">
      {loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                placeholder="From"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom) : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                name="to_date"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.personsList &&
                  props.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handlePersonFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" />}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={() =>
              convertToPDF(jsonData, moment(selectedDateFrom).format('YYYY-MM-DD'), moment(selectedDateTo).format('YYYY-MM-DD'))
            }
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
          </IconButton>

          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            disabled={props.loading}
            onClick={handleSync}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={
                  timePeriod &&
                  timePeriod.map(time => ({
                    id: time.value,
                    text: time.label,
                  }))
                }
                noOptionsText={'--Period--'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleTimePeriodFilter}
                value={selectedTimePeriod}
                disabled={loading}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield entities_report_filter_fields"
                    placeholder="Time period"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <div className="time-clock-container" style={{ marginBottom: '30px' }}>
        <div className="globalTableHeaderGradientColor sticky_div">
          <div className="subcontractorTableHeaderCell deleteShiftTimeCell">Shift Date/ Time</div>
          <div className="subcontractorTableHeaderCell deleteShiftServiceAreaCell">Service Area</div>
          <div className="subcontractorTableHeaderCell deleteShiftStatusCell">Status</div>
          <div className="subcontractorTableHeaderCell deleteShiftAssigneeCell">Assignee Name</div>
          <div className="subcontractorTableHeaderCell deleteShiftReasonCell">Reason</div>
          <div className="subcontractorTableHeaderCell deleteShiftDeletedByCell">Deleted By</div>
          <div className="subcontractorTableHeaderCell deleteShiftActionCell">Action</div>
        </div>

        <InfiniteScroll
          dataLength={records.length}
          scrollThreshold="200px"
          next={() => {
            setPageNumber(pageNumber + 1);
          }}
          hasMore={true}
          loader={''}
        >
          <TableContainer component={Paper}>
            <Table stickyHeader className={classes.table} aria-label="simple table">
              {/* <div className="addWhiteSpaceinTimeclocktable" /> */}
              {records && records.length > 0 ? (
                <TableBody className="timeclockbody">
                  {records.map((shift, index) => (
                    <TableRow key={index} className="deletedShiftsOuterRows">
                      <TableCell align="center" className="timeclockBodyCell deleteShiftTimeCell">
                        <span>{dateFTConverter(shift.startDate, dateFormat)}</span>
                        <br></br>
                        <span style={{ fontSize: '13px', fontStyle: 'italic', color: '#9170e2' }}>
                          {moment(shift.start_time).format('LT') + ' - ' + moment(shift.end_time).format('LT')}
                        </span>
                      </TableCell>
                      <TableCell align="center" className="timeclockBodyCell deleteShiftServiceAreaCell">
                        {shift.service_area ? shift.service_area : null}
                      </TableCell>
                      <TableCell align="left" className="timeclockBodyCell deleteShiftStatusCell">
                        <span>{shift.status ? shift.status : null}</span>
                      </TableCell>
                      <TableCell align="left" className="timeclockBodyCell deleteShiftAssigneeCell">
                        <span>{shift.assignee ? shift.assignee : null}</span>
                      </TableCell>
                      <TableCell align="center" className="timeclockBodyCell deleteShiftReasonCell">
                        <span>{shift.reason ? shift.reason : null}</span>
                      </TableCell>
                      <TableCell align="center" className="timeclockBodyCell deleteShiftDeletedByCell">
                        <span>{shift.deleted_by ? shift.deleted_by : null}</span>
                        <br></br>
                        <span style={{ fontSize: '13px', fontStyle: 'italic', color: '#9170e2' }}>
                          {shift.deleted_at &&
                            moment(shift.deleted_at).format('Do MMMM YYYY') + ' - ' + moment(shift.deleted_at).format('LT')}
                        </span>
                      </TableCell>

                      <TableCell align="center" className=" timeclockBodyCell deleteShiftActionCell">
                        <Button
                          style={{ width: '15vh', backgroundColor: '#d28ad4', color: 'white', fontSize: '16px', height: '45px' }}
                          variant="contained"
                          size="small"
                          onClick={() => {
                            handleRescheduleShift(shift.id);
                          }}
                          className="deleted-shifts-reschedule-button"
                        >
                          &nbsp;Restore&nbsp;
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                !loading && <div className="no-record-warning">No Shifts found</div>
              )}
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </div>
    </div>
  );
}
const mapStateToProps = (storeState: IRootState) => ({
  shifts: storeState.timeclock.entities,
  personsList: storeState.person.filterPersons,
  locationsList: storeState.serviceArea.entities,
  clientList: storeState.client.entities,
  loading: storeState.timeclock.loading,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  organizationSettings: storeState.organizationSettings.storedOrganizationSettings,
  deleted_shifts: storeState.shift.deleted_shifts,
  rescheduleSuccess: storeState.shift.rescheduleSuccess,
});

const mapDispatchToProps = {
  getDeletedShifts,
  getPersonForFilters,
  getPersons,
  resetPerson,
  rescheduleShift,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect<Record<string, unknown>>(mapStateToProps, mapDispatchToProps)(DeletedShifts);
